import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import SalesforceGuard from '../auth/SalesforceGuard';
import {
  ACCOUNTS_PATH,
  ACCOUNT_ID,
  AI_PATH,
  AUTH_PATH,
  DEALS_PATH,
  DEAL_ID,
  GONG_AUTH_PATH,
  GONG_PATH,
  HOME_PATH,
  LOGIN_PATH,
  MEETINGS_PATH,
  MEETING_ID,
  NOTES_PATH,
  NOTE_ID,
  PEOPLE_PATH,
  SALESFORCE_AUTH_PATH,
  SALESFORCE_LOADING_PATH,
  SALESFORCE_PATH,
  TEMPLATES_PATH,
  TEMPLATE_ID,
} from '../config';
import DashboardLayout from '../layouts/dashboard';
import {
  AccountPage,
  AccountPageDeals,
  AccountPageNotes,
  AccountsPage,
  AuthPage,
  DealPage,
  DealPageContextAI,
  DealPageMeetings,
  DealPageNotes,
  DealPagePeople,
  DealsPage,
  GongPage,
  LoginPage,
  MeetingsPage,
  NotePage,
  NotesPage,
  PageNotFoundMessagePage,
  SalesforceAuthPage,
  SalesforceLoadingPage,
  SalesforcePage,
  TemplatePage,
  TemplatesPage,
} from './elements';
import GongGuard from 'src/auth/GongGuard';
import GongAuthPage from 'src/pages/auth/GongAuthPage';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <SalesforceGuard>
              <GongGuard>
                <Navigate to={MEETINGS_PATH} replace={true} />
              </GongGuard>
            </SalesforceGuard>
          ),
        },
        {
          path: MEETINGS_PATH,
          element: <MeetingsPage />,
        },
        {
          path: ACCOUNTS_PATH,
          children: [
            { element: <AccountsPage />, index: true },
            {
              path: `:${ACCOUNT_ID}/*`,
              element: <AccountPage />,
              children: [
                { path: '', element: <Navigate to={`./${DEALS_PATH}`} replace /> },
                { path: DEALS_PATH, element: <AccountPageDeals /> },
                {
                  path: NOTES_PATH,
                  element: <AccountPageNotes />,
                  children: [{ path: `:${NOTE_ID}/*`, element: <AccountPageNotes /> }],
                },
              ],
            },
          ],
        },
        {
          path: NOTES_PATH,
          element: <NotesPage />,
        },
        {
          path: `${NOTES_PATH}/:${NOTE_ID}/*`,
          element: <NotePage />,
        },
        {
          path: TEMPLATES_PATH,
          element: <TemplatesPage />,
        },
        {
          path: `${TEMPLATES_PATH}/:${TEMPLATE_ID}/*`,
          element: <TemplatePage />,
        },
        {
          path: DEALS_PATH,
          children: [
            { element: <DealsPage />, index: true },
            {
              path: `:${DEAL_ID}`,
              element: <DealPage />,
              children: [
                {
                  path: NOTES_PATH,
                  element: <DealPageNotes />,
                  children: [{ element: <DealPageNotes />, path: `:${NOTE_ID}/*` }],
                },
                {
                  path: MEETINGS_PATH,
                  element: <DealPageMeetings />,
                  children: [{ element: <DealPageMeetings />, path: `:${MEETING_ID}/*` }],
                },
                { path: `${PEOPLE_PATH}/*`, element: <DealPagePeople /> },
                {
                  path: `${AI_PATH}/*`,
                  element: <DealPageContextAI />,
                  children: [{ element: <DealPageContextAI />, path: `:${NOTE_ID}/*` }],
                },
              ],
            },
          ],
        },
        // for backward compatibility
        {
          path: '/home/*',
          element: <Navigate to={`/${HOME_PATH}`} replace={true} />,
        },
        {
          path: '*',
          element: <PageNotFoundMessagePage />,
        },
      ],
    },
    {
      path: LOGIN_PATH,
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: AUTH_PATH,
      element: <AuthPage />,
    },
    {
      path: GONG_PATH,
      element: (
        <AuthGuard>
          <GongPage />
        </AuthGuard>
      ),
    },
    {
      path: GONG_AUTH_PATH,
      element: (
        <AuthGuard>
          <GongAuthPage />
        </AuthGuard>
      ),
    },
    {
      path: SALESFORCE_PATH,
      element: (
        <AuthGuard>
          <SalesforcePage />
        </AuthGuard>
      ),
    },
    {
      path: SALESFORCE_AUTH_PATH,
      element: (
        <AuthGuard>
          <SalesforceAuthPage />
        </AuthGuard>
      ),
    },
    {
      path: SALESFORCE_LOADING_PATH,
      element: (
        <AuthGuard>
          <SalesforceLoadingPage />
        </AuthGuard>
      ),
    },
  ]);
}
