import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback } from 'react';

import DealUsers from '../@types/api/dealUsers';
import GongCalls from '../@types/api/gongCalls';
import MeetingTypes from '../@types/api/meetingTypes';
import Meetings from '../@types/api/meetings';
import NoteTemplates, { TemplateType } from '../@types/api/noteTemplates';
import NoteUsers from '../@types/api/noteUsers';
import Notes, { NotesSource } from '../@types/api/notes';
import People from '../@types/api/people';
import SalesforceOpportunityStages from '../@types/api/salesforceOpportunityStages';
import { SearchResults, UsersSearchResults } from '../@types/api/search';
import { AccessLevel } from '../@types/api/types/accessLevel';
import { SalesforceType as BESalesforceType } from '../@types/api/types/configTypes';
import { PrimaryId } from '../@types/api/types/id';
import { MeetingsMeta } from '../@types/api/types/meetings';
import Users from '../@types/api/users';
import Meeting from '../@types/core/meeting';
import { AuthStateType } from '../auth/types';
import { apiQuery } from '../utils/apiQuery';
import { sortAlphabetically } from '../utils/sortAlphabetically';
import Companies from 'src/@types/api/companies';
import NoteTemplateUsers from 'src/@types/api/templateUsers';
import Deal from 'src/@types/core/deal';
import User from 'src/@types/core/user';
import { ILinkField } from 'src/components/tip-tap-editor/slash-menu/SalesforceComponent/LinkFieldModal';
import { IGetDealsInPeriodParams } from 'src/sections/core/deals/Deals';
import { IDropDownItem } from 'src/sections/core/deals/DealsDropDown';
import { IEditSalesforceField } from 'src/sections/core/deals/useTableCell';

export const useGetTeamUsers = (): UseQueryResult<IDropDownItem[]> =>
  useQuery({ queryKey: ['teamUsers'], queryFn: () => apiQuery(`/users/team`) });

export const useGetDealsList = (): UseQueryResult<{ data: Deal[]; totalCount: number }> =>
  useQuery({
    queryKey: ['deals'],
    queryFn: () => apiQuery(`/deals`),
    staleTime: 10000,
  });

export const useGetDealsInPeriod = (searchParams: any): UseQueryResult<IGetDealsInPeriodParams> => {
  const queryString = new URLSearchParams(searchParams).toString();
  return useQuery({
    queryKey: ['deals-detail', searchParams],
    queryFn: () => apiQuery(`/deals/detail?${queryString}`),
    placeholderData: (previousData: any) => previousData,
  });
};

export const useGetDeal = (dealId: string): UseQueryResult<Deal> =>
  useQuery({
    queryKey: ['deal', dealId],
    queryFn: () => apiQuery(`/deals/${dealId}`),
    enabled: !!dealId,
  });

export const useGetDealMeetingsList = (dealId: string): UseQueryResult<Meeting[]> =>
  useQuery({
    queryKey: ['dealMeetings', dealId],
    queryFn: () => apiQuery(`/deals/${dealId}/meetings`),
    enabled: !!dealId,
  });

export const useGetDealPeople = (dealId: string): UseQueryResult<People[]> =>
  useQuery({
    queryKey: ['dealPeople', dealId],
    queryFn: () => apiQuery(`/deals/${dealId}/people`),
    enabled: !!dealId,
    staleTime: 0,
  });

export const useGetDealUsers = (dealId: string): UseQueryResult<Users[]> =>
  useQuery({
    queryKey: ['dealUsers', dealId],
    queryFn: () => apiQuery(`/deals/${dealId}/users`),
    enabled: !!dealId,
  });

export const useGetDealGongCalls = (dealId: string, enable: boolean): UseQueryResult<GongCalls[]> =>
  useQuery({
    queryKey: ['deal', dealId, 'gongCalls'],
    queryFn: () => apiQuery(`/deals/${dealId}/gongCalls`),
    enabled: !!dealId && enable,
    staleTime: 60000,
  });

export const useGetDealStages = (): UseQueryResult<SalesforceOpportunityStages[]> =>
  useQuery({
    queryKey: ['stages'],
    queryFn: () => apiQuery(`/deals/data/stages`),
  });

export const useGetCompany = (companyId: string): UseQueryResult<Companies> =>
  useQuery({
    queryKey: ['company', companyId],
    queryFn: () => apiQuery(`/companies/${companyId}`),
    enabled: !!companyId,
  });

export const useGetCompanyUsers = (companyId: string): UseQueryResult<DealUsers[]> =>
  useQuery({
    queryKey: ['companyUsers', companyId],
    queryFn: () => apiQuery(`/companies/${companyId}/users`),
    enabled: !!companyId,
  });

export const useCreateMeeting = (dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createMeeting'],
    mutationFn: (TVariables: { dealId: string; meta: MeetingsMeta }): Promise<Meeting> =>
      apiQuery(`/meetings`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['dealMeetings', dealId] });
    },
  });
};

export const useDeleteMeeting = (id: string, dealId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteMeeting'],
    mutationFn: () =>
      apiQuery(`/meetings/${id}`, {
        method: 'DELETE',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['dealMeetings', dealId] });
    },
  });
};

export const useUpdateMeeting = (id: string, dealId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updateMeeting'],
    mutationFn: (TVariables: { meta: MeetingsMeta }) =>
      apiQuery(`/meetings/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['meeting', id] });
      queryClient.invalidateQueries({ queryKey: ['dealMeetings', dealId] });
    },
  });
};

export const useGetMeetings = (
  startAt: Date,
  endAt: Date
): UseQueryResult<{ data: Meeting[]; totalCount: number }> =>
  useQuery({
    queryKey: ['meetings', format(startAt, 'yyyy-MM-dd'), format(endAt, 'yyyy-MM-dd')],
    queryFn: () =>
      apiQuery(
        `/meetings?params.startAt=${startAt.toISOString()}&params.endAt=${endAt.toISOString()}`
      ),
  });

export const useGetMeeting = (meetingId: PrimaryId): UseQueryResult<Meetings> =>
  useQuery({
    queryKey: ['meeting', meetingId],
    queryFn: () => apiQuery(`/meetings/${meetingId}`),
    enabled: !!meetingId, // Ensures the query only runs if meetingId is defined
  });

// TODO change this please, just temporary type
type SalesforceType = {
  status: 200 | 401 | 511;
  accessToken: string;
  instanceUrl: string;
};

export const useGetSalesforceToken = (): UseQueryResult<SalesforceType> =>
  useQuery({
    queryKey: ['salesforce'],
    queryFn: () => apiQuery<SalesforceType>('/providers/auth/salesforce/token'),
    staleTime: 21600000, // Stale for 6 hours
  });

// TODO change this please, just temporary type
// type GongType = {
//   status: 200 | 401 | 511;
//   accessToken: string;
// };

export const useGetGongToken = (): UseQueryResult<SalesforceType> =>
  useQuery({
    queryKey: ['gong'],
    queryFn: () => apiQuery<SalesforceType>('/providers/auth/gong/token'),
    staleTime: 21600000, // Stale for 6 hours
  });

export const useGetMeetingTypes = (): UseQueryResult<MeetingTypes[]> =>
  useQuery({
    queryKey: ['meetingTypes'],
    queryFn: () => apiQuery(`/meetings/types`),
  });

export const useDeleteNote = (source: NotesSource, noteId: PrimaryId, sourceId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteNote', source, noteId],
    mutationFn: (TVariables: { sourceId: PrimaryId }) =>
      apiQuery(`/notes/${noteId}`, {
        method: 'DELETE',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', source] });

      if (source === NotesSource.Deal || source === NotesSource.AI) {
        queryClient.invalidateQueries({ queryKey: ['deal', sourceId] });
      }
      if (source === NotesSource.Company) {
        queryClient.invalidateQueries({ queryKey: ['company', sourceId] });
      }
    },
  });
};

export const useDuplicateNote = (source: NotesSource, noteId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['duplicateNote', source, noteId],
    mutationFn: (TVariables: { sourceId: string }) =>
      apiQuery(`/notes/${noteId}/duplicate`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', source] });
    },
  });
};

export const useMoveNoteToDealNote = (source: NotesSource, noteId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['moveUserNoteToDealNote', source, noteId],
    mutationFn: (TVariables: { dealId: string }) =>
      apiQuery(`/notes/${noteId}/moveToDeal`, {
        method: 'PATCH',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', source] });
      queryClient.invalidateQueries({ queryKey: ['deal', source] });
    },
  });
};

export const useSetNoteId = (source: NotesSource, noteId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['noteId', noteId],
    mutationFn: (TVariables: { parentNoteId: string }) =>
      apiQuery(`/notes/${noteId}/assign`, {
        method: 'PATCH',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes', source] });
      queryClient.invalidateQueries({ queryKey: ['note', source, noteId] });
    },
  });
};

export const useSetNote = (sourceId: PrimaryId, dealId?: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['note', sourceId],
    mutationFn: (TVariables: { value: any; parentNoteId?: PrimaryId }) =>
      apiQuery(`/notes/${sourceId}`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['notes', NotesSource.Deal] });
    },
  });
};

export const useGeneratePdfFromNote = () =>
  useMutation({
    mutationKey: ['generatePdf'],
    mutationFn: (TVariables: { html: string }): Promise<any> =>
      apiQuery<Response>(`/notes/generate-pdf`, {
        method: 'POST',
        body: JSON.stringify({ ...TVariables }),
      }),
  });

export const useCreateNote = (source: NotesSource, sourceId?: PrimaryId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['create-note'],
    mutationFn: (TVariables: {
      valueBinary: string;
      value?: any;
      parentNoteId?: PrimaryId;
    }): Promise<Notes> =>
      apiQuery(`/notes/${source}/${sourceId}`, {
        method: 'POST',
        body: JSON.stringify({ ...TVariables, sourceId }),
      }),
    onSuccess: async (newNote) => {
      if (
        source === NotesSource.Deal ||
        source === NotesSource.Meeting ||
        source === NotesSource.People ||
        source === NotesSource.AI
      ) {
        await queryClient.cancelQueries({ queryKey: ['deal', sourceId] });

        const previousNotes = queryClient.getQueryData(['deal', sourceId]);

        queryClient.setQueryData(['deal', sourceId], (old: any) => {
          const updated = structuredClone(old);
          updated.notes = [newNote, ...updated.notes];
          return updated;
        });

        return { previousNotes };
      }
      if (source === NotesSource.User) {
        queryClient.invalidateQueries({ queryKey: ['notes', source] });
      }
      if (source === NotesSource.Company) {
        queryClient.invalidateQueries({ queryKey: ['company', sourceId] });
      }
    },
  });
};

export const useUpdateDealAccessLevel = (dealId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (TVariables: {
      userId: PrimaryId;
      accessLevel: AccessLevel;
      user?: User;
      update?: boolean;
    }) =>
      apiQuery(`/deals/${dealId}/share`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onMutate: async (newUser) => {
      await queryClient.cancelQueries({ queryKey: ['deal', dealId] });

      const previousSharedUsers = queryClient.getQueryData(['deal', dealId]);

      queryClient.setQueryData(['deal', dealId], (old: any) => {
        const updated = structuredClone(old);
        if (newUser.update) {
          updated.dealUsers.forEach((u: any) => {
            if (u.userId === newUser?.userId) {
              u.accessLevel = newUser.accessLevel;
              return;
            }
          });
          return updated;
        }
        updated.dealUsers = [...updated.dealUsers, newUser].sort(
          sortAlphabetically((user) => user.user?.name)
        );
        return updated;
      });

      return { previousSharedUsers };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['deal', dealId], context?.previousSharedUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['deals'] });
    },
  });
};

export const useUpdateNoteAccessLevel = (noteId: PrimaryId, source: NotesSource) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['noteAccessLevel', noteId],
    mutationFn: (TVariables: {
      userId: PrimaryId;
      accessLevel: AccessLevel;
      user?: User;
      update?: boolean;
    }) =>
      apiQuery(`/notes/${noteId}/share`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['note', noteId] });
    },
  });
};

export const useUpdateNoteTemplateAccessLevel = (
  templateNoteId: PrimaryId,
  source: NotesSource
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['noteTemplateAccessLevel', templateNoteId],
    mutationFn: (TVariables: { userId: PrimaryId; accessLevel: AccessLevel; update?: boolean }) =>
      apiQuery(`/notes/templates/${templateNoteId}/share`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onMutate: async (newUser) => {
      await queryClient.cancelQueries({ queryKey: ['noteTemplate', templateNoteId] });

      const previousSharedUsers = queryClient.getQueryData(['noteTemplate', templateNoteId]);
      queryClient.setQueryData(['noteTemplate', templateNoteId], (old: any) => {
        const updated = structuredClone(old);
        if (newUser.update) {
          updated.noteTemplateUsers.forEach((u: any) => {
            if (u.userId === newUser?.userId) {
              u.accessLevel = newUser.accessLevel;
              return;
            }
          });
          return updated;
        }

        updated.noteTemplateUsers = [...updated.noteTemplateUsers, newUser].sort(
          sortAlphabetically((user) => user.user?.name)
        );

        return updated;
      });

      return { previousSharedUsers };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['noteTemplate', templateNoteId], context?.previousSharedUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['noteTemplates', source] });
      queryClient.invalidateQueries({ queryKey: ['noteTemplate', templateNoteId] });
      queryClient.invalidateQueries({ queryKey: ['noteTemplates'] });
    },
  });
};

export const useRemoveDealAccess = (dealId: PrimaryId, userId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['removeDeal', dealId],
    mutationFn: (): Promise<DealUsers> =>
      apiQuery(`/deals/${dealId}/share/${userId}`, {
        method: 'DELETE',
      }),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['deal', dealId] });

      const previousSharedUsers = queryClient.getQueryData(['deal', dealId]);

      queryClient.setQueryData(['deal', dealId], (old: any) => {
        const updated = structuredClone(old);
        updated.dealUsers = updated?.dealUsers
          .filter((u: any) => u.userId !== userId)
          .sort(sortAlphabetically((user: any) => user.user?.name));
        return updated;
      });

      return { previousSharedUsers };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['deal', dealId], context?.previousSharedUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['deals'] });
    },
  });
};

export const useRemoveNoteAccess = (noteId: PrimaryId, userId: PrimaryId, source: NotesSource) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['removeNote', noteId],
    mutationFn: (): Promise<NoteUsers> =>
      apiQuery(`/notes/${noteId}/share/${userId}`, {
        method: 'DELETE',
      }),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['note', noteId] });

      const previousSharedUsers = queryClient.getQueryData(['note', noteId]);

      queryClient.setQueryData(['note', noteId], (old: any) => {
        const updated = structuredClone(old);
        updated.noteUsers = updated?.noteUsers
          .filter((u: any) => u.userId !== userId)
          .sort(sortAlphabetically((user: any) => user.user?.name));
        return updated;
      });

      return { previousSharedUsers };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['note', noteId], context?.previousSharedUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['note', noteId] });
      queryClient.invalidateQueries({ queryKey: ['notes', source] });
    },
  });
};

export const useRemoveNoteTemplateAccess = (
  noteTemplateId: PrimaryId,
  userId: PrimaryId,
  source: NotesSource
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['removeNoteTemplate', noteTemplateId],
    mutationFn: (): Promise<NoteTemplateUsers> =>
      apiQuery(`/notes/templates/${noteTemplateId}/share/${userId}`, {
        method: 'DELETE',
      }),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['noteTemplate', noteTemplateId] });

      const previousSharedUsers = queryClient.getQueryData(['noteTemplate', noteTemplateId]);
      queryClient.setQueryData(['noteTemplate', noteTemplateId], (old: any) => {
        const updated = structuredClone(old);
        updated.noteTemplateUsers = updated?.noteTemplateUsers
          .filter((u: any) => u.userId !== userId)
          .sort(sortAlphabetically((user: any) => user.user?.name));
        return updated;
      });

      return { previousSharedUsers };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['noteTemplate', noteTemplateId], context?.previousSharedUsers);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['noteTemplate', noteTemplateId] });
      queryClient.invalidateQueries({ queryKey: ['noteTemplates', source] });
      queryClient.invalidateQueries({ queryKey: ['noteTemplates'] });
    },
  });
};

export const useSetMeetingDealId = (
  meetingId: PrimaryId,
  currentDeal: PrimaryId,
  selectedDeal?: PrimaryId,
  startAt?: number | string,
  endAt?: number | string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['meetings', meetingId],
    mutationFn: (TVariables: { dealId: string | null }): Promise<Meeting> =>
      apiQuery(`/meetings/${meetingId}/assign`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meetings', startAt, endAt] });
      queryClient.invalidateQueries({ queryKey: ['deal', currentDeal] });
      queryClient.invalidateQueries({ queryKey: ['deal', selectedDeal] });
      queryClient.invalidateQueries({ queryKey: ['dealMeetings', currentDeal] });
    },
  });
};

export const useSaveMeetingType = (meetingId: PrimaryId, dealId?: PrimaryId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['saveMeetingType', meetingId],
    mutationFn: (TVariables: { meetingTypeId: string }): Promise<Meeting> =>
      apiQuery(`/meetings/${meetingId}`, {
        method: 'PATCH',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['meeting', meetingId] });
      queryClient.invalidateQueries({ queryKey: ['dealMeetings', dealId] });
    },
  });
};

export const useGetUserAccount = (enabled: boolean = true): UseQueryResult<AuthStateType> =>
  useQuery({
    queryKey: ['userAccount'],
    queryFn: () => apiQuery(`/users/account`),
    staleTime: 60000,
    enabled,
  });

export const useGetUsers = (): UseQueryResult<User[]> =>
  useQuery({
    queryKey: ['users'],
    queryFn: () => apiQuery(`/admin/users`),
    staleTime: 10000,
  });

export const useAddExternalAttendee = (meetingId: PrimaryId, dealId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['addExternalAttendee', meetingId],
    mutationFn: (TVariables: { title: string; name: string; email: string }) =>
      apiQuery(`/meetings/${meetingId}/attendees`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['meeting', meetingId] });
    },
  });
};

export const useGetSalesforceFields = (enable: boolean): UseQueryResult<any[]> =>
  useQuery({
    queryKey: ['salesforce-meta', enable],
    queryFn: () => apiQuery(`/salesforce/meta`),
    staleTime: 60000,
    enabled: enable,
  });

export const useGetSalesforceOptions = (
  salesforceType: string,
  open: boolean
): UseQueryResult<ILinkField[]> =>
  useQuery({
    queryKey: ['salesforce-options', salesforceType],
    queryFn: () => apiQuery(`/salesforce/${salesforceType}`),
    enabled: open,
  });

export const useGetAiConfig = (): UseQueryResult<any> =>
  useQuery({
    queryKey: ['ai-config'],
    queryFn: () => apiQuery(`/ai/config`),
    staleTime: 60000,
  });

export const useGetFieldValueFromSalesforce = (
  salesforceType: BESalesforceType,
  salesforceKey: string
) =>
  useMutation({
    mutationKey: ['fieldValueFromSalesforce', salesforceType, salesforceKey],
    mutationFn: (TVariables: { additionalField: string }) =>
      apiQuery(`/salesforce/${salesforceType}/${salesforceKey}`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
  });

export const useEditSalesforceField = (
  salesforceType: BESalesforceType,
  salesforceKey: string,
  dealId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    onError: (error) => {
      console.log(`onError`, error);
    },

    mutationKey: ['editSalesforce', salesforceKey, salesforceType],
    mutationFn: (TVariables: { [salesforceField: string]: string }) =>
      apiQuery(`/salesforce/${salesforceType}/${salesforceKey}`, {
        method: 'PATCH',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
    },
  });
};

export const useEditSalesforceFieldInTable = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation({
    mutationKey: ['editSalesforceFieldInTable'],
    mutationFn: ({
      salesforceType,
      salesforceKey,
      data,
    }: {
      salesforceType: string;
      salesforceKey: string;
      data: { [salesforceField: string]: string };
    }) =>
      apiQuery(`/salesforce/${salesforceType}/${salesforceKey}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deals'] });
      queryClient.invalidateQueries({ queryKey: ['deals-detail'] });
    },
  });

  const editSalesforceField = useCallback(
    async ({ salesforceType, salesforceKey, data }: IEditSalesforceField) => {
      try {
        return await mutateAsync({ data, salesforceType, salesforceKey });
      } catch (error) {
        console.error(error);
      }
    },
    [mutateAsync]
  );

  return {
    editSalesforceField,
    isLoading: status === 'pending',
  };
};

export const useGetAllTemplates = (): UseQueryResult<NoteTemplates[]> =>
  useQuery({
    queryKey: ['noteTemplates'],
    queryFn: () => apiQuery(`/notes/templates`),
    staleTime: 60000,
  });

export const useGetTemplate = (noteTemplateId: string): UseQueryResult<NoteTemplates> =>
  useQuery({
    queryKey: ['noteTemplate', noteTemplateId],
    queryFn: () => apiQuery(`/notes/templates/${noteTemplateId}`),
    staleTime: 0,
  });

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-template'],
    mutationFn: (TVariables: {
      name?: string;
      value?: any;
      type?: TemplateType;
    }): Promise<NoteTemplates> =>
      apiQuery(`/notes/templates`, {
        method: 'POST',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['noteTemplates'] });
    },
  });
};

export const useDuplicateTemplate = (templateId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['duplicateNote', templateId],
    mutationFn: (): Promise<NoteTemplates> =>
      apiQuery(`/notes/templates/${templateId}/duplicate`, {
        method: 'POST',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['noteTemplates'] });
    },
  });
};

export const useDeleteTemplate = (templateId: PrimaryId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteTemplate'],
    mutationFn: () =>
      apiQuery(`/notes/templates/${templateId}`, {
        method: 'DELETE',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['noteTemplates'] });
    },
  });
};

export const useGetSearchResult = (searchParams: string): UseQueryResult<SearchResults> =>
  useQuery({
    queryKey: ['search', searchParams],
    queryFn: () =>
      apiQuery(
        `/search/?type=deals&type=companies&type=meetings&search=${encodeURIComponent(
          searchParams
        )}`
      ),
    enabled: !!searchParams.trim(),
  });

export const useGetUsersSearchResult = (searchParams: string): UseQueryResult<UsersSearchResults> =>
  useQuery({
    queryKey: ['searchUsers', searchParams],
    queryFn: () => apiQuery(`/search/?type=users&search=${encodeURIComponent(searchParams)}`),
    enabled: !!searchParams.trim(),
  });

export const useTogglePinnedDeal = (dealId: PrimaryId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['togglePin', dealId],
    mutationFn: () =>
      apiQuery(`/deals/${dealId}/togglePin`, {
        method: 'PATCH',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['deals'] });
    },
  });
};

export const useGetPersonRoles = (): UseQueryResult<string[]> =>
  useQuery({
    queryKey: ['people-roles'],
    queryFn: () => apiQuery(`/people/roles`),
  });

export const useSetPersonFields = (personId: PrimaryId, dealId: PrimaryId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['people-editing', personId],
    mutationFn: (TVariables: {
      name?: string | null;
      title?: string | null;
      linkedIn?: string | null;
      phone?: string | null;
      role?: string | null;
      image?: null;
    }) =>
      apiQuery(`/people/${personId}`, {
        method: 'PATCH',
        body: JSON.stringify(TVariables),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['dealPeople', dealId] });
      queryClient.invalidateQueries({ queryKey: ['dealUsers', dealId] });
    },
  });
};

export const useSetPersonImage = (personId: PrimaryId, dealId: PrimaryId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['people-editing', personId],
    mutationFn: (TVariables: { formData: any }) =>
      apiQuery(
        `/people/${personId}`,
        {
          method: 'PATCH',
          body: TVariables.formData,
        },
        null
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deal', dealId] });
      queryClient.invalidateQueries({ queryKey: ['dealPeople', dealId] });
      queryClient.invalidateQueries({ queryKey: ['dealUsers', dealId] });
    },
  });
};
